import { Auth } from 'aws-amplify';
import { useEffect, useRef } from 'react';
import { User } from 'src/models';

const EVENTS = ['mousemove', 'keydown', 'click', 'scroll'];
const TIMEOUT = 15 * 60 * 1000;

export const useIdleTimeout = (user: User, timeout = TIMEOUT) => {
  const timeoutRef = useRef(null);

  const resetTimeout = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      Auth.signOut();
    }, timeout);
  };

  useEffect(() => {
    if (user.profile.kind === 'disabled_idle') {
      timeoutRef.current = null;
      return;
    }

    EVENTS.forEach((e) => window.addEventListener(e, resetTimeout));

    return () => {
      EVENTS.forEach((e) => window.removeEventListener(e, resetTimeout));
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);
};
