import { Activity, Asset, Report, Stat, State } from 'src/models';
import { activityPriorityComparator } from './comparators';

export const activityPriority: Record<Activity, number> = {
  [Activity.ARCHIVED]: 1,
  [Activity.PENDING]: 2,
  [Activity.OFFLINE]: 3,
  [Activity.NO]: 4,
  [Activity.MODERATE]: 5,
  [Activity.SIGNIFICANT]: 6
};

export const activitySortOrder: Record<Activity, number> = {
  [Activity.ARCHIVED]: 1,
  [Activity.NO]: 2,
  [Activity.MODERATE]: 3,
  [Activity.SIGNIFICANT]: 4,
  [Activity.PENDING]: 5,
  [Activity.OFFLINE]: 6
};

export const getActivityList = (exclude?: Activity[]): Activity[] =>
  Object.keys(activityPriority)
    .filter((a: Activity) => isNaN(Number(a)) && !(exclude ?? []).includes(a))
    .sort(activityPriorityComparator) as Activity[];

const getActivityFromAsset = (asset: Asset): Activity | null => {
  if (!asset) return Activity.PENDING;
  if (asset.isWatermelon) return Activity.NO;
  return null;
};

const getActivityFromState = (state: State | undefined): Activity | null => {
  if (!state) return null;
  if ([State.DISABLED, State.LOOSE, State.BROKEN].includes(state)) return Activity.OFFLINE;
  if (state === State.MAINTENANCE) return Activity.PENDING;
  if (state === State.ARCHIVED) return Activity.ARCHIVED;
  return null;
};

const getActivityFromReport = (report: Report): Activity => report?.activity ?? Activity.PENDING;

export const getActivity = (asset: Asset, report: Report, state?: State): Activity =>
  getActivityFromState(state) || getActivityFromAsset(asset) || getActivityFromReport(report);

export const toPercentages = (
  stats: Stat
): {
  activity: Activity;
  percentage: string;
}[] => {
  if (stats.totalStrips === 0)
    return getActivityList([Activity.ARCHIVED]).map((activity) => ({
      activity,
      percentage: '0%'
    }));

  const order = [Activity.NO, Activity.MODERATE, Activity.SIGNIFICANT, Activity.OFFLINE, Activity.PENDING];

  const percentages = order.map((activity) => (stats[activity] / stats.totalStrips) * 100);

  const roundedPercentages = percentages.map((percentage) => {
    if (percentage > 0 && percentage < 5) return 5; // Minimum floor of 5% if > 0
    return Math.ceil(percentage / 5) * 5; // Round to the nearest 5
  });

  let totalRounded = roundedPercentages.reduce((sum, val) => sum + val, 0);
  while (totalRounded > 100) {
    let maxIndex = roundedPercentages.indexOf(Math.max(...roundedPercentages));
    roundedPercentages[maxIndex] -= 5;
    totalRounded -= 5;
  }
  while (totalRounded < 100) {
    let minIndex = roundedPercentages.indexOf(Math.min(...roundedPercentages.filter((val) => val > 0)));
    roundedPercentages[minIndex] += 5;
    totalRounded += 5;
  }

  return order.map((activity, index) => ({
    activity,
    percentage: `${roundedPercentages[index]}%`
  }));
};
