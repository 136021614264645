import { checkDelayedTenant, checkTenant, getTenantList, Tenant } from './Tenant';

export type Access = {
  available: Tenant[];

  tenant: Tenant;
  isAdmin: boolean;
  isStaff: boolean;
  isArchivist: boolean;
  isCustomer: boolean;
  isEnd: boolean;
  isBasic: boolean;

  isDelayed: boolean;

  asTenant: Tenant;
  asAdmin: boolean;
  asStaff: boolean;
  asArchivist: boolean;
  asCustomer: boolean;
  asEnd: boolean;
  asBasic: boolean;
};

export const getInitAccessState = (): Access => ({
  available: [Tenant.BASIC],

  tenant: Tenant.BASIC,
  isAdmin: false,
  isStaff: false,
  isArchivist: false,
  isCustomer: false,
  isEnd: false,
  isBasic: false,

  isDelayed: false,

  asTenant: Tenant.BASIC,
  asAdmin: false,
  asStaff: false,
  asArchivist: false,
  asCustomer: false,
  asEnd: false,
  asBasic: false
});

export const getAccessState = (tenant: Tenant, asTenant: Tenant): Access => ({
  available: getTenantList(tenant),

  tenant,
  isAdmin: checkTenant(tenant, Tenant.ADMIN),
  isStaff: checkTenant(tenant, Tenant.STAFF),
  isArchivist: checkTenant(tenant, Tenant.ARCHIVIST),
  isCustomer: checkTenant(tenant, Tenant.CUSTOMER) || checkTenant(tenant, Tenant.DLD_CUSTOMER),
  isEnd: checkTenant(tenant, Tenant.END) || checkTenant(tenant, Tenant.DLD_END),
  isBasic: checkTenant(tenant, Tenant.BASIC) || checkTenant(tenant, Tenant.DLD_BASIC),

  isDelayed: checkDelayedTenant(tenant),

  asTenant,
  asAdmin: checkTenant(asTenant, Tenant.ADMIN),
  asStaff: checkTenant(asTenant, Tenant.STAFF),
  asArchivist: checkTenant(asTenant, Tenant.ARCHIVIST),
  asCustomer: checkTenant(asTenant, Tenant.CUSTOMER) || checkTenant(asTenant, Tenant.DLD_CUSTOMER),
  asEnd: checkTenant(asTenant, Tenant.END) || checkTenant(asTenant, Tenant.DLD_END),
  asBasic: checkTenant(asTenant, Tenant.BASIC) || checkTenant(asTenant, Tenant.DLD_BASIC)
});
