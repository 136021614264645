import { FC, createContext, useContext, useEffect, useMemo, useState } from 'react';

import useMenuState, { MenuState } from 'src/hooks/MenuStateHook';
import { Tenant, User, checkTenant, getInitAccessState, getInitials, getUserTenant, profileFactory } from 'src/models';

import { useApp } from './AppContext';
import { Access, getAccessState } from 'src/models';
import { useIdleTimeout } from 'src/hooks/IdleTimeoutHook';

interface IUserContext {
  user: User;
  menu: MenuState;
  access: Access;
  changeAccess: (asTenant: Tenant) => void;
}

const UserContext = createContext<IUserContext>({} as IUserContext);

const UserProvider: FC = ({ children }) => {
  const { cognito } = useApp();
  const [access, setAccess] = useState<Access>(getInitAccessState);

  const user: User = useMemo(
    () => ({
      id: cognito.attributes.sub, // cognito id
      email: cognito.attributes.email,
      givenName: cognito.attributes.given_name,
      familyName: cognito.attributes.family_name,
      companyName: cognito.attributes['custom:company'],
      tosVersion: cognito.attributes['custom:tos_version'],
      picture: cognito.attributes.picture,
      initials: getInitials(cognito.attributes.given_name, cognito.attributes.family_name),
      profile: profileFactory(cognito.attributes.profile),
    }),
    [cognito]
  );

  const menu = useMenuState(user.id);

  const changeAccess = (asTenant: Tenant) => {
    localStorage.setItem('tenant', asTenant);
    setAccess((prev) => getAccessState(prev.tenant, asTenant));
  };

  useIdleTimeout(user);

  useEffect(() => {
    const tenant = getUserTenant(cognito.getSignInUserSession().getAccessToken());
    const savedTenant = (localStorage.getItem('tenant') as Tenant) ?? tenant;
    const asTenant = checkTenant(tenant, savedTenant) ? savedTenant : tenant;

    setAccess(getAccessState(tenant, asTenant));
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        menu,
        access,
        changeAccess
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export const useUser = (): IUserContext => useContext(UserContext);

export default UserProvider;
