import { Profile } from './Profile';

export type User = {
  id: string;
  email: string;
  familyName: string;
  givenName: string;
  companyName: string;
  tosVersion: string;
  picture: string;
  initials: string;
  profile: Profile;
};

export const getInitials = (givenName: string | undefined, familyName: string | undefined): string =>
  `${givenName?.charAt(0) ?? ''}${familyName?.charAt(0) ?? ''}`;
