export type ProfileType = 'disabled_idle' | 'default';

export interface Profile {
  kind: ProfileType;
}

export interface DisabledIdleProfile extends Profile {}

export interface DefaultProfile extends Profile {}

export const profileFactory = (profile: string): Profile => {
  switch (profile) {
    case 'disabled_idle':
      return {
        kind: 'disabled_idle'
      } as DisabledIdleProfile;
    default:
      return {
        kind: 'default'
      } as DefaultProfile;
  }
};
