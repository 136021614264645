export type ZoneState = {
  names: Record<string, string>; // id -> name
  selected: Record<string, boolean>; // name -> isSelected [NOTE: requirement from ECharts]
  order: string[];
  highest: string[];
};

export const getInitZoneState = (highest?: string[]): ZoneState => ({
  names: {},
  selected: {},
  order: [],
  highest: highest ?? []
});
